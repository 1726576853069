export const messages = {
  ra: {
    action: {
      add: "Add",
      update: "Update",
      add_filter: "Add filter",
      back: "Go back",
      bulk_actions: "1 item selected |||| %{smart_count} items selected",
      cancel: "Cancel",
      clear_input_value: "Clear value",
      clone: "Clone",
      close: "Close",
      close_menu: "Close menu",
      confirm: "Confirm",
      create: "Create",
      delete: "Delete",
      edit: "Edit",
      expand: "Expand",
      export: "Export",
      list: "List",
      open_menu: "Open menu",
      refresh: "Refresh",
      remove: "Remove",
      remove_filter: "Remove filter",
      save: "Save",
      search: "Search",
      show: "Show",
      sort: "Sort",
      undo: "Undo",
      publish: "Publish",
      select: "Select",
      unselect: "Unselect",
      move_up: "Move up",
      move_down: "Move down",
      accept: "Accept",
      restore: "Restore",
      discard: "Discard",
      send: "Send",
    },
    auth: {
      auth_check_error: "Please sign in to continue",
      logout: "Logout",
      password: "Password",
      sign_in: "Sign in",
      sign_in_error: "Authentication failed, please try again",
      user_menu: "Profile",
      username: "Username",
      change: "CHANGE",
      email: "Email",
      title: "LOGIN",
      forgot: "forgot my password",
    },
    forgot: {
      title: "Forgot password",
      subtitle: "Enter your email to reset your password.",
      email: "Email",
      button: "Reset password",
      password: "Enter a password",
      password_confirm: "Confirm password",
      message: "The email to change the password has been sent.",
    },
    boolean: {
      true: "Yes",
      false: "No",
      null: " ",
    },
    input: {
      file: {
        upload_several: "Drag some files to upload, or click to select them.",
        upload_single: "Drag a file to upload, or click to select it.",
      },
      image: {
        upload_several: "Drag some images to upload, or click to select them.",
        upload_single: "Drag an image to upload, or click to select it.",
      },
      references: {
        all_missing: "Unable to find reference data.",
        many_missing:
          "At least one of the associated references seems to be unavailable.",
        single_missing: "The associated reference seems to be unavailable.",
      },
      password: {
        toggle_visible: "Hide password",
        toggle_hidden: "Show password",
      },
    },
    message: {
      about: "About",
      are_you_sure: "Are you sure?",
      bulk_delete_content:
        "Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?",
      bulk_delete_title:
        "Delete %{name} |||| Delete %{smart_count} %{name} items",
      delete_content: "Are you sure you want to delete this item?",
      delete_title: "Delete %{name} #%{id}",
      details: "Details",
      error:
        "An error occurred on the client and your request could not be completed",
      invalid_form: "Required fields are missing to save",
      loading: "The page is loading, please wait a moment",
      no: "No",
      not_found: "Either you typed a wrong URL, or you followed a wrong link.",
      yes: "Yes",
      unsaved_changes:
        "Some changes were not saved. Do you still want to leave?",
      max_select: "Only %{max} items can be selected",
      continue: "Do you wish to continue?",
      restore: "Are you sure you want to restore it?",
    },
    navigation: {
      next: "Next",
      no_more_results: "Page %{page} is out of bounds. Try the previous page.",
      no_results: "No results found",
      page_out_from_begin: "You can't go before page 1",
      page_out_from_end: "You can't go after the last page",
      page_out_of_boundaries: "Page number %{page} out of boundaries",
      page_range_info: "%{offsetBegin} - %{offsetEnd} of %{total}",
      page_rows_per_page: "Rows per page:",
      prev: "Previous",
      skip_nav: "Skip Nav",
    },
    sort: {
      sort_by: "Sort by %{field} %{order}",
      ASC: "ascending",
      DESC: "descending",
    },
    notification: {
      bad_item: "Incorrect item",
      canceled: "Action canceled",
      created: "Item created",
      data_provider_error:
        "Data provider error. Check the console for more details.",
      deleted: "Item deleted |||| %{smart_count} items deleted.",
      http_error: "Communication error with the server",
      item_doesnt_exist: "The item doesn't exist",
      logged_out: "Your session has ended, please log in again.",
      updated: "Item updated |||| %{smart_count} items updated",
      i18n_error: "Translations for the specified language could not be loaded",
      element_restored: "The item has been successfully restored",
      element_restored_fail: "Failed to restore the item",
      settings_save: "Saved configuration",
      ticket_created: "Ticket created successfully",
      copy_elements: "Copied elements!",
      link_used: "The link entered is in use",
      note_preview_error:
        "The preview could not be generated for this note. Try again later.",
    },
    page: {
      create: "Create %{name}",
      dashboard: "Home",
      edit: "%{name} #%{id}",
      empty: "No %{name} yet.",
      error: "Something went wrong",
      invite: "Do you want to add one?",
      list: "%{name} List",
      loading: "Loading",
      not_found: "Not found",
      show: "%{name} #%{id}",
    },
    validation: {
      email: "Must be a valid email",
      maxLength: "Must be %{max} characters or less",
      maxValue: "Must be %{max} or less",
      minLength: "Must be at least %{min} characters",
      minValue: "Must be at least %{min}",
      number: "Must be a number",
      oneOf: "Must be one of: %{options}",
      regex: "Must match a specific format (regexp): %{pattern}",
      required: "Required",
      password_mismatch: "Passwords must match",
      field_required: "The field is required",
    },
  },
  seo: {
    title_extension: "Optimal title extension",
    title_extension_helper: "Must be between 20 and 70 characters with spaces",
    has_image: "Has a main image",
    image_include_epigraph: "The image includes an epigraph",
    include_volanta: "Includes Kicker",
    include_pompadour: "Includes pompadour",
    pompadour_extension: "Optimal pompadour extension",
    pompadour_extension_helper:
      "Must be between 50 and 120 characters with spaces",
    content_extension: "Optimal content extension",
    content_extension_helper: "Must exceed 500 words",
    use_bold: "Use bold",
    include_links: "Includes internal links",
    include_links_helper:
      "If you include at least one link to another page on the site, either using “You may be interested” or linking",
    has_tag: "Has at least one tag",
    has_author: "Has an author",
    keywords: "You have at least 3 keywords defined",
    meta_description: "Includes a meta description",
    meta_description_helper:
      "This is what will be displayed in search engines. We recommend not exceeding 120 characters",
    meta_description_include_keywords:
      "The meta description includes the keywords",
    title_include_keywords: "The title includes at least one of the keywords",
    assistant: "SEO Assistant",
    approved_validations: "Approved validations",
  },
  components: {
    focal_point: "Focal Point",
    locked: "Locked",
    locked_title: "Sorry, %{name} has ownership of this resource",
    locked_no_permissions: "You don't have permissions",
    locked_text: "Contact that person to obtain permissions.",
    free_content: "Free Content",
    premium_content: "Premium Content",
    paper_content: "Paper Content",
    category_name: "Category name",
    edit_category: "Edit Category",
    edit_author: "Edit author",
    edit_subscribe_active: "Edit active subscription for",
    name: "Name",
    medio: "Medio",
    delete_components: "The component will be removed.",
    total_views: "Total views",
    date: "Date",
    see_all: "See all",
    fact_data: "Billing information",
    order_detail: "Order detail",
    advertiser: "Advertiser",
    phone: "Phone",
    guest: "Guest",
    business_name: "Business name",
    unique_tax: "Unique tax identification",
    tax_attribute: "Tax category",
    description_too_long: "Description too long",
    no_answer: "No response",
    no_audio_comp: "Your browser does not support the audio element.",
    no_video_comp: "Your browser does not support the video element.",
    title: "Title",
    currency_sign: "US$",
    history: "Historic",
    entries: "notes",
    without_entries: "No notes",
    new_subscription_for: "New subscription for ",
    new_subscription: "New subscription",
    subscription_helper:
      "*The current subscription will be canceled with the creation of a new one. If it is the same plan, it will be renewed.",
    select_audio: "Select audio",
    copy_to_assemble: "Copy to assemble",
    advertising_type: "Advertising Type",
    plan_status: "Plan Status",
    problem: "Problem",
    improvement_suggestion: "Improvement suggestion",
    technical_support: "Technical support",
    attachScreenshot: "Attach screenshot",
    optional: "Optional",
    priority: "Priority",
    priorities: {
      urgent: "Urgent",
      high: "High",
      medium: "Half",
      low: "Low",
    },
    description: "Description",
    description_problem_text:
      "Please describe the problem or suggestion as much as you can, including the section where the problem occurred, your device, and the browser you were using.",
    create_ticket_text:
      "A ticket will be created automatically and you will receive an email with all the updates that the support team makes to it.",
    locale_utc: "",
    currency: "USD",
    locale_language: "en-US",
    subscriber: "Subscriber",
    admin: "User",
    card: "Card",
    black: "Black",
    white: "White",
    start_date: "Start date",
    add_line: "Add line",
    add_in_notes: "Add in notes",
    add_user: "Add new user",
    add_author: "Add new author",
    previous_subscription: "Previous subscriptions",
    no_plan: "No Plan",
    expired: "Expiration",
    payment_date: "Payment date",
    amount: "Amount",
    new_profile: "New profile",
    edit_profile: "Edit profile",
    current_subscribe: "Current Subscription",
    cards: "Cards associated with",
    no_card: "No card",
    address: "Address",
    edit_user: "Edit registered user",
    password: "Password",
    change_password: "Change password",
    profile: "Profile",
    view_online: "Watch online",
    deleted: "Eliminated",
    enabled: "Enabled",
    disabled: "Disabled",
    object: "Base object",
    attribute: "Attribute name",
    define_object: "Define object",
    reduced_view: "Show in reduced view",
    possession: "Possession",
    editing: "Editing",
    component: "Component",
    created_by: "Created by",
    last_update: "Last modification",
    share: "Share on networks",
    shared_content: "This content was already shared in the last 30 days on",
    preview: "Preview",
    restore_note: "Restore backup",
    previous_note: "There is a more recent version of the note",
    select_component: "Select the component to display",
    internet: "Web",
    suggest: "Suggest",
    paper: "Paper",
    free: "Free",
    premium: "Premium",
    update: "Update",
    add: "Add",
    audio: "Audio",
    gallery: "Gallery",
    selected: "selected",
    news: "News",
    category: "Category",
    video: "Video",
    interested: "You may be interested",
    multimedia_url: "Media URL",
    multimedia_url_helper: "Used only for SEO optimization",
    update_last_days: "Updated in the last 30 days",
    search_news: "Search News",
    news_not_found: "No news has been found with these search criteria.",
    loading_tags: "Loading tags...",
    loading_categories: "Loading categories...",
    main_category: "Main category",
    other_categories: "Other categories",
    select_to_copy: "Select where to copy the content",
    copy_to: "Copy content to...",
    copy: "Copy to ",
    copy_paper: "Copy to paper",
    copy_internet: "Copy to Web",
    select_fields: "Select fields",
    select_fields_to_copy: "Select the fields you want to copy",
    press_enter: "Press enter to separate the words",
    chars: "Characters",
    long_title: "Title too long",
    chars_with_blank: "Characters with spaces",
    chars_without_blank: "Characters without spaces",
    select_medio: "Origin of the note (middle)",
    title_section: "Section title",
    subtitle_section: "Section subtitle",
    title_banner: "Banner title",
    text: "Text",
    button_text: "Button text",
    background: "Background color",
    font_color: "Font color",
    show_copete: "Show lead",
    image: "Image",
    main_image: "Main image",
    select_image: "Select image",
    note: "Note",
    notes: "Notes",
    hero_form_title: "Type of hero",
    image_position: "Image position",
    image_style: "Imagen style",
    position: "Position",
    type: "Type",
    none: "None",
    all: "All",
    modality: "Modality",
    modalities: {
      chat: "Chat",
      channel: "Channel",
    },
    whatsapp_number: "WhatsApp number",
    whatsapp_text: "WhatsApp predefined text",
    whatsapp_link_channel: "Link to WhatsApp channel",
    show_icon_premium: "Show premium icon",
    exclude_categories: "Exclude categories",
    iframe_full_width: {
      tooltip: "Only number of pixeles",
      size: "Height of iframe",
    },
    imagen_note_form: {
      help_image:
        "We recommend that it be a 300x400 px image or 3:4 aspect ratio",
    },
    positions: {
      top: "Top",
      bottom: "Bottom",
      center: "Center",
    },
    list_positions: {
      center: "Center",
      top_left: "Top Left",
      top_right: "Top Right",
      bottom_left: "Bottom left",
      bottom_right: "Bottom right",
    },
    size: "Size",
    height: "Height",
    sizes: {
      cover_name: "Cover",
      cover_tip:
        "The image will go to 100% width. Important: If it is a vertical image, the top and bottom margins may not be displayed.",
      contain_name: "Contain",
      contain_tip:
        "The image will be contained within the component. Important: if it is a vertical image, it will have empty spaces on the sides.",
    },
    size_scale: {
      big: "Big",
      medium: "Medium",
      small: "Small",
    },
    audio_per_page: "Audios per page:",
    image_per_page: "Images per page:",
    news_per_page: "News per page:",
    corrected_status_change: "The correction status will change.",
    free_editor: "Free editor",
    premium_editor: "Premium editor",
    paper_editor: "Paper editor",

    select_news: "Select News",
    catalog_list: {
      Bomba: "Bomb",
      GrillaX3: "Grid x3",
      GrillaX4: "Grid x4",
      GrillaX6: "Grid x6",
      GrillaX9: "Grid x9 - With advertising",
      Grilla3X3: "Grid 3x3",
      GrillaX5: "Grid x5",
      GrillaX4WithoutImg: "Grid x4 - Without images",
      GrillaX7: "Grid x7",
      GridX8: "Grid x8 - With advertising",
      GrillaSimple: "Grid without images",
      GrillaX3Max: "Grid 1 featured + 2 secondary",
      GrillaX8WithoutPrimaryCard: "Grid x8 - Without Primary Card",
      GridX7WithAdvertising: "Grid x7 - With Advertising",
      GridX6WithPrimaryCard: "Grid x6 - With Primary Card",
      GridLastNews: "Grid x4 + Latest News section",
      IndividualGridHover: "Grid x4 - With animation",
      GridCardPublish: "Grid x6 - With ads",
      Hero: "Image Hero",
      Publicidad: "Advertising",
      Publicidadx4: "Advertising Grid x 4",
      ImagenNotas: "Image + 2 Notes",
      WhatsApp: "News on WhatsApp",
      SubscribeCTA: "Subscription Banner",
      IframeFullWidth: "Iframe Full Width",
    },
  },
  resources: {
    types: {
      name: "Entity Type",
      confirm_title:
        "This entity type and all generated entities will be deleted. This action cannot be undone. Do you want to proceed with the deletion?",
      confirm_content: "",
      new: "Create",
      fields: {
        name: "Name",
        attributes: "Attributes",
        attribute: "Attribute",
        format: "Field Format",
        options: "Options",
        option: "Value",
        show: "Show in note",
      },
    },
    relations: {
      name: "Relations",
      fields: {
        name: "Name",
        entity_id: "Origin Entity",
        related_id: "Destination Entity",
        relation_type_id: "Relation Type",
      },
    },
    relation_types: {
      name: "Relation Types",
      fields: {
        name: "Name",
        type_id: "Entity Type",
      },
    },
    entities: {
      name: "Entity",
      plural_name: "Entities",
      confirm_title:
        "The entity %{name} will be deleted. This action cannot be undone. Do you want to proceed with the deletion?",
      confirm_content: "",
      confirm_title_bulk:
        "The %{smart_count} entity/ies and their relations with the contents will be deleted. This action cannot be undone. Do you still want to delete them?",
      new: "Create",
      fields: {
        name: "Name",
        order: "Order",
        description: "Description",
        short_description: "Short Description",
        type_id: "Type",
        attributes: "Attributes",
        field: "Attribute",
        value: "Value",
        slug: "Slug",
      },
    },
    metrics: {
      name: "Metrics",
      validation: {
        not_found: "No data found for the selected period",
        empty_message: "Please enter a date range to start.",
        date_order: "'To' date cannot be before 'From' date.",
        invalid:
          "Error submitting the request, please check the highlighted fields.",
      },
      titles: {
        content_per_day: "Content Created per Day",
        content_per_user: "Content Created per User",
        content_only_free: "Content Only FREE",
        content_only_premium: "Content Only PREMIUM",
        content_free_premium: "Content FREE and PREMIUM",
        other_content: "Other Content",
        content_total: "Total Content Created",
        characters_free: "Average Characters per FREE Content",
        characters_premium: "Average Characters per PREMIUM Content",
        characters_paper: "Average Characters per PAPER Content",
        content_paper: "PAPER Contents",
        title_users: "Users",
        title_subscriptions: "Metrics",
        title_history: "Registered History",
        filter_registered: "Users Registered",
        sub_by_plan: "Subscribers by Plan",
        singups: "Signups",
        signrenews: "Total Signups and Renewals",
        optouts: "Total Optouts",
        comments: "Total Comments",
        churn_rate: "Churn Rate",
        sub_url: "Pre-subscription URL",
        url: "URL",
        bounced: "Bounced",
        unfulfilled: "Unfulfilled",
        fulfilled: "Fulfilled",
        successful: "Subscribed",
        count_users: "Visited",
        objective_content: "Objective Content",
        date_created: "Date Created",
        note_id: "Note ID",
        category: "Category",
      },
      descriptions: {
        bounced: "The user did not select any plan.",
        unfulfilled:
          "The user selected a plan but did not complete the registration or payment process.",
        fulfilled:
          "The user completed the payment process, regardless of whether it was processed successfully or not.",
        successful: "The user successfully subscribed.",
        count_users:
          "Number of times a user visited the page, counting from the first visit. Counts notes accessed directly.",
        show: "Display the note on the page.",
      },
      input: {
        from: "From",
        to: "To",
        users: "Filter by User",
      },
    },
    share: {
      network: "Social Network",
      message: "Text",
      status: "Publication Status",
      status_confirm_title: "Select how to share the content",
      notification: {
        success: "Note shared successfully",
        error: "An error occurred while sharing the note",
      },
    },
    pages: {
      name: "Pages",
      successMessage: "Changes generated successfully",
      fields: {
        name: "Name",
        updated_at: "Last Modification",
        locked_by: "Last access",
        config: "Configuration",
        show_section: "Show Space",
        position: "Image Position",
        position_center: "Centered",
        position_top: "Top",
        position_bottom: "Bottom",
        type_hero: "Hero Type",
        type_hero_large: "Large",
        type_hero_small: "Small",
        type_hero_medium: "Medium",
        title_section: "Section Title",
        description: "Description",
        count_section: "Number of Headlines",
        link_section: "Destination Link",
        link_sponsor: "Sponsor Link",
        sponsor: "Sponsor",
        link_iframe: "Player Link",
        show_section_tag: "Show Category Tag",
        show_section_copete: "Show Lead",
        category: "Categories",
        color: "Alert Type and Color",
        fontcolor: "Font Color",
        background: "Background Color",
        helper_title_image: "Title Image 150x50 px",
      },
    },
    notes: {
      name: "Contents",
      successMessage: "Content generated successfully",
      validation: {
        date_publish: "Minutes must be '00' or '30'",
      },
      alert_autosave: "Unsaved changes found",
      slug_helper_text: "Spaces or special characters are not allowed",
      seo_helper:
        "To help position even better in search engines, such as Google.",
      keywords_helper: "Those words that indicate what the content is about.",
      meta_description_helper:
        "Which summarizes the content. It will help increase your traffic from search engines.",
      observation_helper: "Considerations for the editor and the owner",

      bulk_actions: {
        restore_label: "Restore",
        restore_confirm_title: "Restore news",
        restore_confirm_content:
          "Are you sure you want to restore these items?",
        status_label: "Publication Status",
        status_confirm_title: "Modify status of selected notes",
        category_id_label: "Category",
        category_id_confirm_title: "Modify categories of selected notes",
        author_id_label: "Author",
        author_id_confirm_title: "Modify author of selected notes",
      },
      notification: {
        success: "Change has been successfully made",
        error: "Error updating data",
      },
      format: {
        standard: "Standard",
        audio: "Audio",
        gallery: "Gallery",
        video: "Video",
      },
      user_interest: {
        inform_name: "Inform me",
        inform_tip:
          "Its objective is to keep the user informed. As an example, a specific event in the city",
        educate_name: "Educate me",
        educate_tip:
          "Its objective is to contribute to the user's educational level. As an example, the approach to new efficient ways of feeding",
        distract_name: "Distract me",
        distract_tip:
          "Its objective is to entertain the user in a relaxed way. As an example, an approach to showbiz",
        inspire_name: "Inspire me",
        inspire_tip:
          "It aims to motivate the user. As an example, stories of resilient people",
      },
      status: {
        draft: "Draft",
        publish: "Publish",
        published: "Published",
        scheduled: "Scheduled",
        schedule: "Schedule",
        deleted: "Deleted",
      },
      corrected: {
        corrected: "Corrected",
        dont_corrected: "Not Corrected",
      },
      fields: {
        content: "Content",
        content_premium: "Premium Content",
        title: "Title",
        slug: "Note Link",
        search: "Search",
        date_publish_paper: "Publication Date on Paper",
        format: "Format",
        created_at: "Creation Date",
        date_publish: "Publication Date",
        title_home: "Home's Title",
        author: "Author Name",
        author_id: "Author",
        status: "Status",
        section_paper: "Paper Section",
        creator_id: "Creator",
        page: "Page",
        priority_paper: "Priority",
        page_paper: "Paper Page",
        keywords: "Keywords",
        meta_description: "Meta description",
        volanta: "Kicker",
        copete: "Lead",
        corrected: "Corrected content",
        epigrafe: "Epigraph",
        enable_comments: "Allow comments",
        observation: "Comments",
        is_cover_note_paper: "Cover Note",
        fixed: "Fixed",
        built: "Built",
        revised: "Revised",
        date_publish_paper_short: "Paper date",
        category_id: "Web category",
        type_content: "Content type",
        deleted_at: "Deleted at",
        date_web: "Web date",
        interest_user_category: "Interest User Category",
      },
    },
    journalists: {
      name: "Users",
      new: "Create",
      fields: {
        first_name: "First Name",
        last_name: "Last Name",
        dni: "ID",
        password: "Password",
        confirm_password: "Confirm Password",
        password_confirmation: "Confirm Password",
        search: "Search",
        roles: "Profile",
      },
      errors: {
        password_mismatch: "Passwords do not match.",
      },
    },
    subscribers: {
      name: "Subscribers",
      new: "Create",
      fields: {
        first_name: "First Name",
        last_name: "Last Name",
        password: "Password",
        dni: "ID",
        confirm_password: "Confirm Password",
        phone_feature: "Area Code",
        phone: "Phone",
        birthday: "Date of Birth",
        dealer: "Dealer",
        address: "Address",
        addr_floor: "Floor",
        addr_door: "Door",
        city: "City",
        country: "Country",
        province: "Province",
        zipcode: "Zip Code",
        password_confirmation: "Confirm Password",
        search: "Search",
        roles: "Plan",
        username: "Username",
        cards_asoc: "Associated cards",
        expire_date: "Fecha de expiración",
        status: "Status",
      },
      status: {
        active: "active",
        renewed: "renewed",
        expired: "expired",
        canceled: "cancelled",
      },
      export: {
        phone_feature: "Phone feature",
        phone: "Phone number",
        birthday: "Birth date",
        name: "Name",
        no: "No",
        yes: "Yes",
        direction: "Address",
        printed: "Printed",
        subscription: "Subscription",
        status_subscription: "Subscription status",
        status: {
          active: "Active",
          expired: "Expired",
          cancelled: "Cancelled",
          unsubscribed: "No subscription",
        },
      },
      errors: {
        password_mismatch: "Passwords do not match.",
        export: "Error converting to csv",
      },
    },
    authors: {
      name: "Authors",
      new: "Create",
      fields: {
        display_name: "First and Last Name",
        first_name: "First Name",
        last_name: "Last Name",
        description: "Description",
        notes_count: "Entries",
        search: "Search",
      },
    },
    categories: {
      name: "Categories",
      new: "Create",
      fields: {
        name: "Name",
        description: "Description",
        search: "Search",
        notes_count: "Associated Contents",
      },
      confirm_title:
        "This category will be deleted. This action cannot be undone. Do you still want to delete it?",
      confirm_content: "",
      disable_delete:
        "This category cannot be deleted. First delete associated contents.",
    },
    photographers: {
      name: "Photographers",
      new: "Create",
      fields: {
        name: "Name",
        description: "Description",
        search: "Search",
      },
    },
    tags: {
      name: "Tags",
      new: "Create",
      fields: {
        name: "Name",
        description: "Description",
        search: "Search",
      },
      confirm_title:
        "The tag '%{name}' and its relations with the contents will be deleted. This action cannot be undone. Do you still want to delete it?",
      confirm_title_bulk:
        "The %{smart_count} tag/s and their relations with the contents will be deleted. This action cannot be undone. Do you still want to delete them?",
      confirm_content: "",
    },
    roles: {
      name: "Roles",
      new: "Create",
      fields: {
        name: "Code",
        title: "Name",
        users_count: "Number of Users",
        search: "Search",
        type: "User Type",
      },
    },
    settings: {
      name: "Settings",
      singular_name: "Setting",
      fields: {
        name: "Name",
        val: "Value",
        type: "Type",
        search: "Search",
      },
    },
    combos: {
      name: "Combos",
      label: "Spaces / Combos",
      new: "New Space / Combo",
      edit: "Edit Combo",
      order: "Sort spaces / combos",
      define: "Define both the order of the media and the spaces within them",
      types: {
        short_text_name: "Short answer",
        short_text_help: "Up to 80 characters. Eg: First and last name",
        paragrahp_name: "Paragraph",
        paragrahp_help:
          "Up to 255 characters. Ideal for requesting longer descriptions, such as text for an advertisement",
        paragrahp_label: "Max characters",
        email_name: "Email",
        email_help: "To collect the advertiser's email address",
        date_name: "Date",
        date_help: "So the advertiser can specify a date or a range of dates",
        date_type: "Type",
        multiple: "Multiple",
        range: "Range",
        simple: "Simple",
        date_range: "Layout",
        free: "Free",
        from_now: "From today",
        to_now: "To today",
        disabled_days: "Check the days you want to disable",
        days: {
          monday: "Monday",
          tuesday: "Tuesday",
          wednesday: "Wednesday",
          thursday: "Thursday",
          friday: "Friday",
          saturday: "Saturday",
          sunday: "Sunday",
        },
        quantity_days: "Maximum quantity of days",
        quantity_days_help: "Number of days to choose",
        number: "Number",
        number_help: "So the advertiser can add quantities",
        check: "Checkboxes",
        check_help:
          "Ideal for offering options where the advertiser must choose one or more",
        radio: "Option list",
        radio_help:
          "Ideal for when the advertiser must choose only one of the options, which do not need description",
        card: "Option cards",
        card_help:
          "Ideal for when the advertiser must choose only one of the options and they need description",
        image_card: "Option cards with images",
        image_card_help:
          "Ideal for when the advertiser must choose only one of the options and they need description and/or image",
        file: "File upload",
        file_help:
          "So the advertiser can upload a file of a certain format. It can be an image, a video, or an audio file",
        file_type: "File type",
      },
      fields: {
        search: "Search",
        name: "Name",
        admedia_id: "Media",
        price: "Price",
        price_discount: "Price with Discount",
        details: "Space Details",
        config: "Steps Configuration",
        recommend: "Recommended",
        active: "Active",
        multiple: "Allow Multiple Selections",
        steps: {
          step: "Step",
          type: "Type",
          question: "Descriptive Text",
          checkout_text: "Summary Text",
          helper_text: "Help Text",
          required: "Required",
          add_step: "Add Step",
          add_field: "ADD FORM FIELD",
          add_option: "ADD OPTION",
        },
      },
      confirm_remove_step: {
        title: "Remove Form",
        message: "Are you sure you want to remove the form?",
      },
      tooltips: {
        lines_description:
          "Add everything that includes the space / combo. You can add a maximum of 5 lines.",
        steps:
          "All the data, dates, or files that you will require the advertiser to provide for publication in this space / combo.",
        question:
          "Text indicating to the advertiser what should be entered in this field. For example, 'Name'.",
        checkout_text:
          "It is the text that will be displayed to the advertiser in the summary of their order. In some cases, it may match the 'Descriptive Text'.",
      },
      duplicate: "Duplicate",
    },
    amedia: {
      name: "Media",
      fields: {
        search: "Search",
        name: "Name",
        description: "Description",
      },
      new: "New Media",
      edit: "Edit Media",
    },
    orders: {
      name: "Orders",
      edit: "Editar Orden",
      space: "Space / combo",
      order_status: {
        pending: "Pending review",
        in_review: "In review",
        ready: "Ready to publish",
        publish: "Published",
      },
      payment_status: {
        pending_payment: "Pending",
        processing: "Processing",
        complete: "Successful",
        failed: "Failed",
        nocomplete: "Not completed",
      },
      payment_method: {
        mp: "Mercado Pago",
        pa: "Paypal",
        ma: "Manual",
      },
      fields: {
        created_at: "Creation Date",
        status: "Order Status",
        payment_method: "Payment Method",
        payment_status: "Payment Status",
        cost: "Price",
        reference: "References",
        combo_id: "Name of space / combo",
      },
    },
    publicly: {
      name: "Publicly",
    },
    submenuMemberships: {
      name: "Memberships",
    },
    polls: {
      name: "Polls",
      new: "Create",
      fields: {
        question: "Question",
        date_question: "Start Poll",
        type: "Type",
        search: "Search",
        active: "Active",
        option_1: "Option 1",
        option_2: "Option 2",
        option_3: "Option 3",
        option_4: "Option 4",
        option_5: "Option 5",
        selected_1: "Number of Votes 1",
        selected_2: "Number of Votes 2",
        selected_3: "Number of Votes 3",
        selected_4: "Number of Votes 4",
        selected_5: "Number of Votes 5",
      },
    },
    media: {
      name: "Multimedia Library",
      fields: {
        title: "Title",
        description: "Description",
        alt_text: "Alternative Text",
        photographer: "Photographer",
        caption: "Caption",
        preview_url: "Preview",
        source_url: "Link",
        media_type: "File Type",
        watermark: "Add Watermark",
        search: "Search",
        apply_crop: "Apply cut",
        discard_crop: "Discard",
        title_crop: "Adjust your image",
      },
      image_maxFilesize:
        "The size must not exceed %{maxSize}. If exceeded, the file will not be uploaded.",
      image_cropped: `The size must not exceed %{maxSize}. If exceeded, the file will not be uploaded.
         You can only Crop one image at time.`,
      audio_maxFilesize:
        "File size cannot exceed %{maxSize}. If exceeded, the file will not be uploaded.",
      maxFilesizeMultiple: "The maximum size of files cannot exceed 80MB",
      selectExisting: "Select Existing",
    },
    newspapers: {
      name: "Print Edition",
      fields: {
        title: "Title",
        date_publish: "Publication Date",
        file_url: "Link",
        thumbnail_url: "Preview",
        search: "Search",
        category: "Category",
      },
      maxFilesize: "File size cannot exceed 5MB",
    },
    comments: {
      name: "Comments",
      detail: "Comment Details",
      fields: {
        user_id: "User",
        note_id: "News",
        date_gte: "Posted since",
        date_lte: "Posted before",
        created_at: "Date",
        comment: "Comment",
        parent_id: "In response to",
        status: "Status",
      },
      action: {
        accept: "Approve",
        reject: "Reject",
      },
      notification: {
        approved_success: "Comment approved",
        approved_error: "Error: Comment could not be approved",
        rejected_success: "Comment rejected",
        rejected_error: "Error: Comment could not be rejected",
      },
    },
    suscriptors: {
      name: "Paid Subscribers",
    },
    plans: {
      name: "Plans",
      new: "Create",
      fields: {
        name: "Plan Name",
        price: "Monthly Price",
        price_total: "Final Price",
        price_discount: "Monthly Price with Discount",
        type: "Billing Frequency",
        created_at: "Date",
        recommend: "Recommend",
        locally: "This plan is exclusive to the locality",
        active: "Show on Page",
        lines_description: "Description",
        role_id: "Associated Profile",
        external: "Payment Gateway",
        external_reference: "Reference",
        subscriptions_count: "Subscribers",
        has_free_trial: "Has Free Trial",
      },
      tooltips: {
        lines_description: "Plan information displayed on the website",
      },
      helper: {
        price_discount: "Leave blank if no bonus included",
        price: "Customer will be charged $%{price} annually",
        active:
          "A plan without an associated payment method cannot be displayed",
      },
      free_trial_mp: {
        title: "Free Trial",
      },
      info_create_cards:
        'This plan includes the benefits club. Upon confirmation, the card order will start automatically. IMPORTANT: If you already have one, the "card registration" process becomes ineffective.',
    },
    club: {
      name: "Benefits",
    },
    benefits: {
      name: "Benefits",
      fields: {
        search: "Search",
        title: "Title",
        category: "Category",
        description: "Description",
        short_description: "Short Description",
        current_image: "Current Image",
        shop: "Shop",
      },
    },
    shop: {
      name: "Shops",
      fields: {
        search: "Search",
        name: "Shop Name",
        address: "Address",
        cuit: "CUIT",
        owner_name: "Owner or Contact Person",
        dni: "DNI",
        email: "Email",
        phone: "Phone",
        benefit_desc: "Description",
        short_desc: "Short Description",
        agreement_date: "Acceptance Date",
      },
    },

    club_cards: {
      name: "Cards",
      new: "Create",
      empty_title: "No cards created yet.",
      empty_body: "Do you want to add one?",
      validation: {
        user_not_dni: "Subscriber does not have complete DNI",
      },
      fields: {
        search: "Search by name",
        dni: "DNI",
        headline: "Cardholder",
        user_id: "Subscriber",
        first_name: "First Name",
        last_name: "Last Name",
        username: "Username",
        email: "Email",
        phone: "Phone",
        birthday: "Date of Birth",
        state: "Card Status",
        printed: "Printed",
        active: "Active",
        address: "Address",
        addr_floor: "Floor",
        addr_door: "Door",
        city: "City",
        country: "Country",
        province: "Province",
        delivery_date: "Delivery Date",
        type: "Card Type",
        notification_plana: "Notify Plan A",
      },
      bulk_actions: {
        print_label: "Mark as Printed",
        print_confirm_title: "Update Prints",
        print_confirm_content:
          "Are you sure you want to mark the selected cards as printed?",
        status_label: "Change Status",
        status_confirm_title: "Select the new status for the card/s",
      },
      notification: {
        success: "Change successfully made",
        error: "Error updating data",
      },
      info_has_card: "This subscriber already has a card",
    },
    users: {
      name: "Users",
    },
    general: {
      name: "General",
    },
    web: {
      name: "Website",
    },
    membership: {
      name: "Memberships",
    },
    subscriptor: {
      name: "Subscribers",
    },
  },
};
