import { Box, Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import * as React from "react";
import { Draggable } from "react-beautiful-dnd";
import BombaForm from "../../../components/dynamicComponentForm/BombaForm";
import GrillaForm from "../../../components/dynamicComponentForm/GrillaForm";
import GrillaFormWithoutImg from "../../../components/dynamicComponentForm/GrillaFormWithoutImg";
import GrillaPublicidadx4 from "../../../components/dynamicComponentForm/GrillaPublicidadx4";
import HeroForm from "../../../components/dynamicComponentForm/HeroForm";
import IframeFullWidth from "../../../components/dynamicComponentForm/IframeFullWidth";
import ImagenNotasForm from "../../../components/dynamicComponentForm/ImagenNotasForm";
import PublicidadForm from "../../../components/dynamicComponentForm/PublicidadForm";
import SubscribeCTA from "../../../components/dynamicComponentForm/SubscribeCTA";
import WhatsAppForm from "../../../components/dynamicComponentForm/WhatsappForm";
import DeleteComponent from "../DeleteComponent";

const useStyles = makeStyles({
  boxComponent: {
    backgroundColor: "#fafafa",
    border: "1px solid #e6e6e6",
    borderRadius: "4px",
    padding: "20px",
    margin: "20px 0",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#fafafa",
    padding: 0,
    minHeight: "auto",
    marginBottom: "15px",
  },
  subtitle: {
    fontSize: "1.5rem",
    fontWeight: 600,
    flex: "1 1 100%",
  },
});

const KeysToComponentMap = {
  Bomba: BombaForm,
  GrillaX3: GrillaForm,
  GrillaX4: GrillaForm,
  GrillaX6: GrillaForm,
  Grilla3X3: GrillaForm,
  GrillaX5: GrillaForm,
  GrillaX4WithoutImg: GrillaFormWithoutImg,
  Hero: HeroForm,
  Publicidad: PublicidadForm,
  Publicidadx4: GrillaPublicidadx4,
  ImagenNotas: ImagenNotasForm,
  WhatsApp: WhatsAppForm,
  SubscribeCTA: SubscribeCTA,
  IframeFullWidth: IframeFullWidth,
  GrillaX6: GrillaForm,
  GrillaX9: GrillaForm,
  GridX6WithPrimaryCard: GrillaForm,
  GrillaX7: GrillaForm,
  GrillaX8: GrillaForm,
  GrillaX8WithoutPrimaryCard: GrillaForm,
  GrillaSimple: GrillaForm,
  Grilla3XMax: GrillaForm,
  GridLastNews: GrillaForm,
  IndividualGridHover: GrillaForm,
  GridCardPublish: GrillaForm,
  GridX7WithAdvertising: GrillaForm,
};

const Item = ({
  item,
  zone,
  handleCallbackUpdateComponent,
  listCategories,
  deleteComponent,
  uuid,
  index,
}) => {
  const classes = useStyles();
  return (
    <Draggable draggableId={`${uuid}`} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classes.boxComponent}
          key={uuid}
        >
          <Box className={classes.toolbar}>
            <Typography
              className={classes.subtitle}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              Componente: {item.name}
            </Typography>
            {zone.edit && <DeleteComponent deleteComponent={deleteComponent} />}
          </Box>

          {typeof KeysToComponentMap[item.key] !== "undefined" &&
            React.createElement(
              KeysToComponentMap[item.key],
              {
                handleCallback: handleCallbackUpdateComponent,
                zone: zone,
                componentData: { ...item.settings },
                listCategories: listCategories,
              },
              null
            )}
        </div>
      )}
    </Draggable>
  );
};

export default Item;
